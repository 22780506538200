import { createApi } from '@reduxjs/toolkit/query/react';
import { Stripe } from 'stripe';
import { SharedEndUserSubscriptionInfoEntity } from '@shared/api-responses';
import customFetchBase from './customFetchBase';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: customFetchBase,
  tagTypes: ['payments'],
  endpoints: (builder) => ({
    getPriceList: builder.query<Stripe.Price[], null>({
      query() {
        return {
          url: 'client-users/listAllPrices/',
          method: 'GET',
        };
      },
    }),
    getSubscriptionInfo: builder.query<
      SharedEndUserSubscriptionInfoEntity,
      { email: string }
    >({
      query(data) {
        return {
          url: `client-users/subscription-info?email=${encodeURIComponent(
            data.email,
          )}`,
          method: 'GET',
        };
      },
    }),
    getPortalUrl: builder.query<
      { redirectUrl: string },
      { stripeCustomerId: string }
    >({
      query(data) {
        return {
          url: `client-users/createPortal?stripeCustomerId=${data.stripeCustomerId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetPriceListQuery,
  useGetSubscriptionInfoQuery,
  useGetPortalUrlQuery,
} = paymentsApi;
