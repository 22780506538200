function PasswordStrengthIndicator({
  passwordStrength,
  isPasswordEmpty,
}: {
  passwordStrength: number;
  isPasswordEmpty: boolean;
}) {
  const getBorderColor = (index: number) => {
    if (passwordStrength === 0 && index === 0 && !isPasswordEmpty) {
      return 'border-red-500 bg-red-500';
    }
    if (passwordStrength === 1 && index <= 1) {
      return 'border-yellow-500 bg-yellow-500';
    }
    if (passwordStrength === 2 && index <= 2) {
      return 'border-lime-500 bg-lime-500';
    }
    if (passwordStrength === 3 && index <= 3) {
      return 'border-green-700 bg-green-700';
    }

    return 'border-gray-500';
  };

  return (
    <div className="mt-6 w-full">
      <p className="flex h-1">
        <span className={`flex-1 border ${getBorderColor(0)}`} />
        <span className={`mx-4 flex-1 border ${getBorderColor(1)}`} />
        <span className={`flex-1 border ${getBorderColor(2)}`} />
        <span className={`ml-4 flex-1 border ${getBorderColor(3)}`} />
      </p>
    </div>
  );
}
export default PasswordStrengthIndicator;
