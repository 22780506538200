import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './services/authApi';
import { userApi } from './services/userApi';
import { rolesApi } from './services/rolesApi';
import { adminApi } from './services/adminApi';
import { paymentsApi } from './services/paymentsApi';
import userReducer from './features/userSlice';
import pageReducer from './features/pageSlice';

export const store = configureStore({
  reducer: {
    // When adding new api here, reset the api state on logout in Header.tsx.
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    user: userReducer,
    route: pageReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authApi.middleware,
      userApi.middleware,
      rolesApi.middleware,
      adminApi.middleware,
      paymentsApi.middleware,
    ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
