import { AiFillCopyrightCircle } from 'react-icons/ai';

export default function Footer() {
  return (
    <div className="mx-8 pb-8">
      <div className="flex items-center">
        <div className="header-font whitehorse-text-gradient text-base md:text-4xl text-primary-v1 xl:text-3xl">
          WhiteHorse
        </div>
      </div>
      <div className="py-6">
        The information provided in this section is for informational purposes
        only and should not be construed as financial, investment, or legal
        advice. Any views or opinions expressed in the content are those of the
        respective authors and do not necessarily reflect the official stance of
        our platform. While we strive to provide accurate and up-to-date
        information, we recommend that readers consult with professional
        advisors and conduct thorough research before making any investment
        decisions. Our platform disclaims any liability for actions taken based
        on the information presented in this section.
      </div>
      <div className="flex items-center">
        <AiFillCopyrightCircle className="text-primary-v1 mr-2 text-3xl" />
        WhiteHorse. All Rights Reserved @ 2023.
      </div>
    </div>
  );
}
