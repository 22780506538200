import { createApi } from '@reduxjs/toolkit/query/react';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  SharedCheckEmailReturnType,
  SharedResetPasswordDto,
  SharedVerifyUserDto,
} from '@shared/api-inputs';
import { SharedEndUserEntity } from '@shared/api-responses';

import customFetchBase from './customFetchBase';
import {
  GenericResponse,
  LoginInput,
  UserJwtObjectType,
  UserRegisterInputType,
} from '../../types';

import { setUser, setUserAsAdmin } from '../features/userSlice';
import { userApi } from './userApi';
import { CommonConstants } from '../../constants';

// Create a separate async thunk to handle storing the access token in localStorage
export const storeAccessToken = createAsyncThunk(
  'auth/storeAccessToken',
  async (accessToken: string) => {
    localStorage.setItem(CommonConstants.ACCESS_TOKEN, accessToken);
  },
);

export const storeIsAdmin = createAsyncThunk(
  'auth/storeIsAdmin',
  async (isAdmin: string) => {
    localStorage.setItem(CommonConstants.IS_ADMIN, isAdmin);
  },
);

export const storeIsOtpValid = createAsyncThunk(
  'auth/storeIsOtpValid',
  async (storeIsOtpValid: string) => {
    localStorage.setItem(CommonConstants.IS_OTP_VALID, storeIsOtpValid);
  },
);

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  tagTypes: ['auth'],
  endpoints: (builder) => ({
    checkUserEmail: builder.mutation<
      SharedCheckEmailReturnType,
      { email: string; forAdmin?: boolean }
    >({
      query: (data) => ({
        url: data.forAdmin
          ? 'auth/client-admin-email'
          : 'auth/client-users-email',
        method: 'POST',
        body: data.forAdmin ? data : { email: data.email },
        credentials: 'include',
      }),
    }),
    loginUser: builder.mutation<
      { access_token: string; isAdmin?: boolean; status: string },
      LoginInput
    >({
      query: (data) => ({
        url: data.forAdmin ? 'auth/client-admins' : 'auth/client-users',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data?.access_token) {
          dispatch(storeAccessToken(data?.access_token));
          if (data?.isAdmin) {
            dispatch(setUserAsAdmin());
            dispatch(storeIsAdmin('true'));
          } else {
            dispatch(storeIsAdmin('false'));
            dispatch(
              authApi.endpoints.getMe.initiate({ isAdmin: data?.isAdmin }),
            );
          }
        }
      },
    }),
    logoutUser: builder.mutation<void, void>({
      query: () => ({
        url: 'auth/logout',
        credentials: 'include',
      }),
    }),
    verifyEmail: builder.mutation<GenericResponse, string>({
      query: (verificationCode) => ({
        url: `auth/verifyemail/${verificationCode}`,
        credentials: 'include',
      }),
    }),
    getEndUser: builder.query<SharedEndUserEntity, null>({
      query() {
        return {
          url: 'client-users',
          method: 'GET',
        };
      },
    }),
    getMe: builder.query<UserJwtObjectType, { isAdmin?: boolean }>({
      query: (data) => ({
        url: data.isAdmin ? 'auth/client-admins/me' : 'auth/client-users/me',
        credentials: 'include',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setUser(data));
          if (data.isAdmin) {
            dispatch(setUserAsAdmin());
            dispatch(storeIsAdmin('true'));
          } else {
            dispatch(storeIsAdmin('false'));
            dispatch(userApi.endpoints.userJourney.initiate(null));
          }
        }
      },
    }),
    registerUser: builder.mutation<
      { userCreated: boolean; statusCode: string },
      UserRegisterInputType
    >({
      query(data) {
        return {
          url: 'client-users',
          method: 'POST',
          body: data,
        };
      },
    }),
    resendVerification: builder.mutation<
      { verificationEmailSent: boolean },
      { email: string }
    >({
      query(data) {
        return {
          url: 'client-users/resend-verification',
          method: 'POST',
          body: data,
        };
      },
    }),
    verifyUser: builder.mutation<
      { userVerified: boolean },
      SharedVerifyUserDto
    >({
      query(data) {
        return {
          url: `client-users/verify?userId=${data.userId}&token=${data.token}&source=${data.source}`,
          method: 'POST',
        };
      },
    }),
    forgotPassword: builder.mutation<{ emailSent: boolean }, { email: string }>(
      {
        query: (body) => ({
          url: 'client-users/forgot-password',
          method: 'POST',
          credentials: 'include',
          body,
        }),
      },
    ),
    resetPassword: builder.mutation<
      { passwordReset: boolean },
      SharedResetPasswordDto
    >({
      query: (data) => ({
        url: 'client-users/reset',
        method: 'POST',
        body: data,
      }),
    }),
    generateOtp: builder.mutation<
      { isOtpGenerated: boolean },
      { email: string }
    >({
      query: (data) => ({
        url: 'client-users/generate-otp',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
    }),
    verifyOtp: builder.mutation<
      { isOtpValid: boolean },
      { email: string; otpCode: string }
    >({
      query: (data) => ({
        url: 'client-users/verify-otp',
        method: 'POST',
        body: data,
        credentials: 'include',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data?.isOtpValid) {
          dispatch(storeIsOtpValid('true'));
        }
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
  useCheckUserEmailMutation,
  useLogoutUserMutation,
  useGetMeQuery,
  useGetEndUserQuery,
  useRegisterUserMutation,
  useResendVerificationMutation,
  useVerifyUserMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useGenerateOtpMutation,
  useVerifyOtpMutation,
} = authApi;
