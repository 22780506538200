import { createApi } from '@reduxjs/toolkit/query/react';
import {
  SharedCompanyAdminConfigEntity,
  SharedBucketEntity,
  SharedBucketInfoWithCompany,
  SharedBucketMappingEntity,
  SharedCompanyEntity,
  SharedEndUserTransactionEntity,
  SharedInvestorAdvisorWithProfile,
  SharedEndUserEntity,
  TypeWithCompanyOrBucketAndEndUserDetails,
  SharedEndUserCoachingEntity,
  SharedEndUserDocumentsEntity,
  SharedBucketDocumentsEntity,
} from '@shared/api-responses';
import {
  SharedBucketDocumentParams,
  SharedBucketDocumentsDto,
  SharedEndUserCoachingDto,
  SharedEndUserTransactionDto,
} from '@shared/api-inputs';

import customFetchBase from './customFetchBase';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: customFetchBase,
  tagTypes: ['admins'],
  endpoints: (builder) => ({
    getCompaniesForAdmin: builder.query<SharedCompanyEntity[], null>({
      query() {
        return {
          url: 'client-admins/companies',
          method: 'GET',
        };
      },
    }),
    getCompanyInfoForAdmin: builder.query<
      SharedCompanyEntity,
      { identifier: string }
    >({
      query(data) {
        return {
          url: `client-admins/company/details/${data.identifier}`,
          method: 'GET',
        };
      },
    }),
    getInvestorInfoForAdmin: builder.query<
      SharedInvestorAdvisorWithProfile,
      { identifier: string }
    >({
      query(data) {
        return {
          url: `client-admins/investor/${data.identifier}`,
          method: 'GET',
        };
      },
    }),
    getCoachingInfoForAdmin: builder.query<
      SharedInvestorAdvisorWithProfile,
      { identifier: string }
    >({
      query(data) {
        return {
          url: `client-admins/mentor/${data.identifier}`,
          method: 'GET',
        };
      },
    }),
    getInvestmentsForAdmin: builder.query<
      TypeWithCompanyOrBucketAndEndUserDetails<SharedEndUserTransactionEntity>[],
      null
    >({
      query() {
        return {
          url: 'client-admins/investments',
          method: 'GET',
        };
      },
    }),
    getCoachingsForAdmin: builder.query<
      TypeWithCompanyOrBucketAndEndUserDetails<SharedEndUserCoachingEntity>[],
      null
    >({
      query() {
        return {
          url: 'client-admins/mentorships',
          method: 'GET',
        };
      },
    }),
    getInvestorsForAdmin: builder.query<
      SharedInvestorAdvisorWithProfile[],
      null
    >({
      query() {
        return {
          url: 'client-admins/investors',
          method: 'GET',
        };
      },
    }),
    getAdvisorsForAdmin: builder.query<
      SharedInvestorAdvisorWithProfile[],
      null
    >({
      query() {
        return {
          url: 'client-admins/mentors',
          method: 'GET',
        };
      },
    }),
    updateUserDetailsForAdmin: builder.mutation<
      SharedEndUserEntity,
      Partial<SharedEndUserEntity>
    >({
      query(data) {
        return {
          url: 'client-admins/users',
          method: 'PATCH',
          body: data,
        };
      },
    }),
    getBucketsForAdmin: builder.query<SharedBucketEntity[], null>({
      query() {
        return {
          url: 'client-admins/buckets',
          method: 'GET',
        };
      },
    }),
    getBucketInfoForAdmin: builder.query<
      SharedBucketInfoWithCompany,
      { bucketId: string }
    >({
      query(data) {
        return {
          url: `client-admins/bucket/${data.bucketId}`,
          method: 'GET',
        };
      },
    }),
    updateBucketInfoForAdmin: builder.mutation<
      SharedBucketEntity,
      Partial<SharedBucketEntity>
    >({
      query(data) {
        return {
          url: 'client-admins/bucket',
          method: 'PUT',
          body: data,
        };
      },
    }),
    updateCompanyMappingBucketForAdmin: builder.mutation<
      SharedBucketMappingEntity[],
      [Partial<SharedBucketMappingEntity>]
    >({
      query(data) {
        return {
          url: 'client-admins/buckets-mapping',
          method: 'PUT',
          body: data,
        };
      },
    }),
    getCompanyAdminConfigForAdmin: builder.query<
      SharedCompanyAdminConfigEntity,
      { companyId: string }
    >({
      query(data) {
        return {
          url: `client-admins/company/admin-configs/${data.companyId}`,
          method: 'GET',
        };
      },
    }),
    updateCompanyAdminConfigForAdmin: builder.mutation<
      SharedCompanyAdminConfigEntity,
      Partial<SharedCompanyAdminConfigEntity>
    >({
      query(data) {
        return {
          url: `client-admins/company/admin-configs/${data.companyId}`,
          method: 'PUT',
          body: data,
        };
      },
    }),
    sendInvestorSubscriptionAgreement: builder.mutation<
      null,
      Partial<SharedEndUserTransactionDto>
    >({
      query(data) {
        return {
          url: 'client-admins/sendInvestorDocusign',
          method: 'POST',
          body: data,
        };
      },
    }),
    sendAdvisorCompanyAgreement: builder.mutation<
      null,
      Partial<SharedEndUserCoachingDto>
    >({
      query(data) {
        return {
          url: 'client-admins/sendAdvisorDocusign',
          method: 'POST',
          body: data,
        };
      },
    }),
    getUserDocumentsForAdmin: builder.query<
      Array<
        TypeWithCompanyOrBucketAndEndUserDetails<SharedEndUserDocumentsEntity>
      >,
      null
    >({
      query() {
        return {
          url: 'client-admins/user-documents',
          method: 'GET',
        };
      },
    }),
    getBucketDocumentsByDocumentTypeForAdmin: builder.query<
      SharedBucketDocumentsEntity[],
      SharedBucketDocumentParams
    >({
      query(params) {
        return {
          url: 'client-admins/bucket-documents',
          method: 'GET',
          params,
        };
      },
    }),
    updateBucketDocumentsForAdmin: builder.mutation<
      SharedBucketDocumentsDto,
      Partial<SharedBucketDocumentsEntity>
    >({
      query(data) {
        return {
          url: 'client-admins/bucket-documents',
          method: 'PUT',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetCompaniesForAdminQuery,
  useGetCompanyInfoForAdminQuery,
  useGetInvestmentsForAdminQuery,
  useGetCoachingsForAdminQuery,
  useGetInvestorInfoForAdminQuery,
  useGetCoachingInfoForAdminQuery,
  useGetInvestorsForAdminQuery,
  useGetAdvisorsForAdminQuery,
  useUpdateUserDetailsForAdminMutation,
  useGetBucketsForAdminQuery,
  useGetBucketInfoForAdminQuery,
  useUpdateBucketInfoForAdminMutation,
  useUpdateCompanyMappingBucketForAdminMutation,
  useGetCompanyAdminConfigForAdminQuery,
  useUpdateCompanyAdminConfigForAdminMutation,
  useSendInvestorSubscriptionAgreementMutation,
  useSendAdvisorCompanyAgreementMutation,
  useGetUserDocumentsForAdminQuery,
  useGetBucketDocumentsByDocumentTypeForAdminQuery,
  useUpdateBucketDocumentsForAdminMutation,
} = adminApi;
