import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../../redux/hooks';
import { setActiveRoute } from '../../redux/features/pageSlice';
import { AppRoutes } from '../../types';
import LandingPageButtons from '../../components/LandingPageButtons';

import RoleSections from './RoleSections';
import InfoSection from './InfoSection';
import HowItWorks from './HowItWorks';
import Footer from './Footer';

function Landing() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveRoute(AppRoutes.HOME));
  }, []);

  return (
    <div>
      <div
        className="min-h-screen max-h-screen flex"
        style={{ maxHeight: '100vh' }}
      >
        <div className="flex-1 flex flex-col max-w-full xl:w-1/2">
          <div className="flex-1 flex items-center mx-8 text-2xl 2xl:text-3xl">
            <div className="flex-col justify-center custom-header-styles">
              <div className="">Democratizing</div>
              <div className="py-1">Venture Capital:</div>
              <div className="">Startup Investments</div>
              <div className="py-1">Made Easy</div>
            </div>
          </div>
          <div className="flex-1 px-8 flex flex-col">
            <div className="hidden md:block 2xl:mr-12">
              Join our syndication platform for collaborative investing, where
              startups and investors thrive together. Explore investment
              opportunities in diversified
              <span className="bg-gradient-to-r from-secondary-v1 to-primary-v1 text-white ml-1">
                Private Startup Portfolios
              </span>
              , aiming to minimize risk in the inherently dynamic startup
              landscape
            </div>
            <div className="py-16 2xl:flex-1 flex justify-center items-center lg:items-start lg:justify-start">
              <LandingPageButtons
                defaultText="SIGNUP FOR FREE"
                hoverText="SURE LETS DO IT"
                onClick={() => {
                  navigate(`${AppRoutes.SIGNUP}`);
                }}
                customClass="bg-secondary-v1"
              />
            </div>
          </div>
        </div>
        <div className="hidden xl:flex justify-end xl:w-1/2 landing-hero">
          <div className="flex-1 flex justify-center flex-col items-end">
            <div className="text-white font-semibold text-xl 2xl:text-3xl mr-8">
              Cross-Border startup investment opportunities,
              <div>highly vetted by our experienced team.</div>
            </div>
          </div>
        </div>
      </div>
      <InfoSection />
      <RoleSections />
      <HowItWorks />
      <Footer />
    </div>
  );
}

export default Landing;
