function LoadingScreen() {
  return (
    <div className="flex-center min-h-screen bg-gray-200 bg-transparent text-primary-v1">
      <div
        className="inline-block h-32 w-32
            animate-spin
            rounded-full
            border-t-8
            border-primary-v1"
      />
    </div>
  );
}

export default LoadingScreen;
