import { useState } from 'react';
import { Button } from 'antd';
import { AiOutlineArrowRight } from 'react-icons/ai';

export default function LandingPageButtons({
  defaultText,
  hoverText,
  onClick,
  customClass,
}: {
  defaultText: string;
  hoverText: string;
  onClick: () => void;
  customClass: string;
}) {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div className="landing-page-custom-buttons flex-center lg:justify-start lg:items-start">
      <Button
        onClick={onClick}
        className={`text-white text-xl font-medium pl-8 pr-4 py-8 flex-center ${customClass}`}
        style={{ borderRadius: '40px' }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {hovered ? (
          <div className="flex-center">
            <div className="h-12 w-12 bg-white rounded-3xl flex-center text-3xl">
              <AiOutlineArrowRight className="text-black font-black" />
            </div>
            <div className="transition-transform transform translate-x-0 ml-4 pr-4">
              {hoverText}
            </div>
          </div>
        ) : (
          <div className="flex-center">
            <div className="transition-transform transform translate-x-0 mr-8">
              {defaultText}
            </div>
            <div className="h-12 w-12 bg-white rounded-3xl flex-center text-3xl">
              <AiOutlineArrowRight className="text-black font-black" />
            </div>
          </div>
        )}
      </Button>
    </div>
  );
}
