/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { CiMoneyBill } from 'react-icons/ci';
import { BiUserCircle } from 'react-icons/bi';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { RiHandCoinLine } from 'react-icons/ri';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { Button, Checkbox, Input, Tooltip } from 'antd';
import { SharedEndUserRoleType } from '@shared/api-inputs';
import { passwordStrength as checkPasswordStrength } from 'check-password-strength';
import LoadingScreen from '../../components/LoadingScreen';
import './Signup.css';
import PasswordStrengthIndicator from '../../components/PasswordStrength';
import { useRegisterUserMutation } from '../../redux/services/authApi';
import { AppRoutes } from '../../types';
import { useAppDispatch } from '../../redux/hooks';
import { setActiveRoute } from '../../redux/features/pageSlice';
import LoginSignupHero from '../../components/LoginSignupHero';

export default function SignupPage() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [formState, setFormState] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    secret: string;
    endUserRole: SharedEndUserRoleType;
    passwordStrength: number;
    usingCustomUrl: boolean;
    isEmailInUrl: boolean;
    inviteCode: string;
    formSubmitted: boolean;
    agreeToSignup: boolean;
  }>({
    firstName: '',
    lastName: '',
    email: '',
    secret: '',
    endUserRole: 'JOB_SEEKER',
    passwordStrength: 0,
    usingCustomUrl: false,
    agreeToSignup: true,
    formSubmitted: false,
    isEmailInUrl: false,
    inviteCode: '',
  });
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    if (searchParams && searchParams.get('code') !== null) {
      const hashedValues = atob(searchParams.get('code') || '');

      const keyValuePairs = hashedValues.split('&');

      // Create an object to store the key-value pairs
      const data: Record<string, string> = {};
      // Loop through the key-value pairs and add them to the object
      keyValuePairs.forEach((pair: string) => {
        const [key, value] = pair.split('=');
        data[key] = value;
      });
      if (
        data.userRole &&
        data.ic &&
        (data.userRole === 'COMPANY' ||
          data.userRole === 'INVESTOR' ||
          data.userRole === 'ADVISOR_OR_COACH')
      ) {
        setFormState({
          ...formState,
          endUserRole: data.userRole as SharedEndUserRoleType,
          inviteCode: data.ic || '',
          usingCustomUrl: true,
          email: data.email || '',
          isEmailInUrl: !isEmpty(data.email),
        });
      }
    }
    if (searchParams && searchParams.get('userRole') !== null) {
      if (
        searchParams.get('userRole') === 'COMPANY' ||
        searchParams.get('userRole') === 'INVESTOR' ||
        searchParams.get('userRole') === 'ADVISOR_OR_COACH'
      ) {
        setFormState({
          ...formState,
          endUserRole: searchParams.get('userRole') as SharedEndUserRoleType,
          usingCustomUrl: true,
        });
      }
    }
    if (searchParams.size === 0) {
      setFormState({
        firstName: '',
        lastName: '',
        email: '',
        secret: '',
        endUserRole: 'JOB_SEEKER',
        passwordStrength: 0,
        agreeToSignup: true,
        usingCustomUrl: false,
        formSubmitted: false,
        isEmailInUrl: false,
        inviteCode: '',
      });
    }
  }, [searchParams]);

  const navigate = useNavigate();

  const [registerUser, { isLoading, isSuccess, isError, error }] =
    useRegisterUserMutation();

  const changeUserRole = (endUserRole: SharedEndUserRoleType) => {
    setFormState((prevState) => ({
      ...prevState,
      endUserRole,
    }));
  };

  const handleSignup = async (e: React.FormEvent<HTMLFormElement>) => {
    setFormState({
      ...formState,
      formSubmitted: true,
    });
    e.preventDefault();
    window.scrollTo(0, 0);
    if (
      formState.passwordStrength > 2 &&
      (formState.endUserRole === 'COMPANY' ||
        formState.endUserRole === 'INVESTOR' ||
        formState.endUserRole === 'ADVISOR_OR_COACH')
    ) {
      await registerUser(formState);
    }
  };

  useEffect(() => {
    if (error) {
      if ('status' in error && error.status === 409) {
        setErrMsg('Email already exists. Please login to continue.');
      } else setErrMsg('Please try after some time');
    }
  }, [error]);

  useEffect(() => {
    dispatch(setActiveRoute(AppRoutes.SIGNUP));
  }, []);

  return (
    <div className="flex min-h-screen min-w-full">
      <div className="min-h-screen w-full xl:w-1/2">
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <div>
            {isSuccess ? (
              <div className="ml-16 mt-16 pt-32">
                <div className="text-3xl">
                  Thanks for registering. Please check your email for
                  verification.
                </div>
                <div className="flex w-1/2 mt-6">
                  <button
                    className="button-primary flex-1 flex justify-between items-center"
                    type="button"
                    onClick={() => navigate(AppRoutes.LOGIN)}
                  >
                    Sign in
                    <div className="text-xl mr-4">
                      <AiOutlineArrowRight />
                    </div>
                  </button>
                </div>
              </div>
            ) : (
              <form onSubmit={handleSignup}>
                <div className="login-signup-container">
                  <h1 className="py-2 text-2xl md:text-4xl">
                    Select your role & Sign up
                  </h1>
                  {!formState.usingCustomUrl ? (
                    <div>
                      <div className="my-4 flex flex-1">
                        <Button
                          onClick={() => changeUserRole('COMPANY')}
                          className={`bg-primary-v1 text-white rounded-3xl font-medium text-xl py-6 flex-1 flex items-center px-6 mr-4 ${
                            formState.endUserRole === 'COMPANY'
                              ? 'text-black shadow-2xl bg-secondary-v1'
                              : ''
                          } `}
                        >
                          <span className="text-xl text-white xl:text-2.5xl">
                            <CiMoneyBill />
                          </span>
                          <div className="flex-1 flex ml-3">
                            Pitch your Startup
                          </div>
                        </Button>
                      </div>
                      <div className="mb-4 flex flex-col md:flex-row">
                        <Button
                          onClick={() => changeUserRole('INVESTOR')}
                          className={`bg-primary-v1 text-white rounded-3xl font-medium text-xl py-2 md:py-6 flex-1 flex items-center px-6 mr-4 ${
                            formState.endUserRole === 'INVESTOR'
                              ? 'text-black shadow-2xl bg-secondary-v1'
                              : ''
                          } `}
                        >
                          <span className="text-xl text-white xl:text-2.5xl">
                            <RiHandCoinLine />
                          </span>
                          <div className="flex-1 flex ml-3">
                            Start Investing
                          </div>
                        </Button>
                        <Button
                          onClick={() => changeUserRole('ADVISOR_OR_COACH')}
                          className={`bg-primary-v1 text-white rounded-3xl font-medium text-xl py-2 md:py-6 flex-1 flex items-center px-6 mr-4 mt-4 md:mt-0 ${
                            formState.endUserRole === 'ADVISOR_OR_COACH'
                              ? 'text-black shadow-2xl bg-secondary-v1'
                              : ''
                          } `}
                        >
                          <span className="text-xl text-white xl:text-2.5xl">
                            <BiUserCircle />
                          </span>
                          <div className="flex-1 flex ml-3">
                            Advisor or Coach
                          </div>
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="py-2 md:text-2xl">
                      <span> Your role -</span>
                      <span className="text-primary-v1 pl-1">
                        {`${
                          formState.endUserRole === 'COMPANY'
                            ? 'Company'
                            : formState.endUserRole === 'INVESTOR'
                              ? 'Investor'
                              : 'Advisor'
                        }`}
                      </span>
                    </div>
                  )}
                  <label
                    htmlFor="name"
                    className="custom-input-width flex flex-col pt-4"
                  >
                    Your First name*
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter your name"
                      className="min-w-custom-input"
                      required
                      value={formState.firstName}
                      onChange={(e) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          firstName: e.target.value,
                        }));
                      }}
                    />
                  </label>
                  <label
                    htmlFor="name"
                    className="custom-input-width flex flex-col pt-4"
                  >
                    Your Last name*
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Enter your name"
                      className="min-w-custom-input"
                      required
                      value={formState.lastName}
                      onChange={(e) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          lastName: e.target.value,
                        }));
                      }}
                    />
                  </label>
                  <label
                    htmlFor="email"
                    className="custom-input-width flex flex-col pt-4"
                  >
                    Email*
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      className="min-w-custom-input"
                      readOnly={formState.isEmailInUrl}
                      disabled={formState.isEmailInUrl}
                      required
                      value={formState.email}
                      onChange={(e) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          email: e.target.value,
                        }));
                      }}
                    />
                  </label>
                  <Tooltip title="Password Requirements: Minimum Length: 10.At least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character.">
                    <label
                      htmlFor="password"
                      className="custom-input-width flex flex-col pt-4"
                    >
                      Password*
                      <Input.Password
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Enter password"
                        className="min-w-custom-input"
                        required
                        value={formState.secret}
                        onChange={(e) => {
                          setFormState((prevState) => ({
                            ...prevState,
                            secret: e.target.value,
                            passwordStrength: checkPasswordStrength(
                              e.target.value,
                            ).id,
                          }));
                        }}
                      />
                    </label>
                  </Tooltip>
                  <PasswordStrengthIndicator
                    passwordStrength={formState.passwordStrength}
                    isPasswordEmpty={formState.secret === ''}
                  />
                  {formState.formSubmitted &&
                    formState.passwordStrength < 3 && (
                      <div className="pt-2 text-sm text-red-700">
                        Password does not meet requirments.
                      </div>
                    )}

                  {formState.formSubmitted &&
                    formState.passwordStrength > 2 &&
                    formState.endUserRole !== 'COMPANY' &&
                    formState.endUserRole !== 'INVESTOR' &&
                    formState.endUserRole !== 'ADVISOR_OR_COACH' && (
                      <div className="pt-2 text-sm text-red-700">
                        Please select your role.
                      </div>
                    )}

                  <label htmlFor="email" className="flex pt-4">
                    <Checkbox
                      name="agreeToSignup"
                      id="agreeToSignup"
                      required
                      value={formState.agreeToSignup}
                    />
                    <div className="pl-2">
                      By checking this box, you agree to our
                      <span className="px-1 text-primary-v1">
                        <Link to={AppRoutes.TERMS} target="_blank">
                          Terms of Service
                        </Link>
                      </span>
                      and
                      <span className="pl-1 text-primary-v1">
                        <Link to={AppRoutes.PRIVACY} target="_blank">
                          Privacy Policy
                        </Link>
                      </span>
                      .
                    </div>
                  </label>
                  {isError && (
                    <div className="pt-2 text-sm text-red-700">{errMsg}</div>
                  )}
                  <div className="pt-6 flex">
                    <button
                      className="button-primary flex-1 flex justify-between items-center"
                      type="submit"
                    >
                      <div className="px-1">Submit</div>
                      <div className="text-xl mr-4">
                        <AiOutlineArrowRight />
                      </div>
                    </button>
                  </div>

                  <div className="pt-2 flex">
                    <button
                      className="button-secondary flex-1 flex justify-between items-center"
                      type="button"
                      onClick={() => navigate(AppRoutes.LOGIN)}
                    >
                      <div className="px-1">
                        Already have an account ? Sign In
                      </div>
                      <div className="text-xl mr-4">
                        <AiOutlineArrowRight />
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        )}
      </div>

      <LoginSignupHero />
    </div>
  );
}
