import { Input } from 'antd';
import React from 'react';

interface InputProps {
  fieldError: boolean;
  inputName: string;
  labelName: string;
  id: string;
  type: string;
  placeHolder: string;
  isOptional: boolean;
  value: string;
  onValueChange: (value: string) => void;
  showErrorMsg: boolean;
  errMsg: string;
}

function CustomFormInput(props: InputProps) {
  const {
    fieldError,
    inputName,
    labelName,
    id,
    type,
    placeHolder,
    isOptional,
    onValueChange,
    value,
    showErrorMsg,
    errMsg,
  } = props;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(e.target.value);
  };

  return (
    <div>
      <label htmlFor={id} className="custom-input-width flex flex-col pt-8">
        <span className="text-primary-v1">
          {labelName}
          {isOptional ? '' : '*'}
        </span>
        {type === 'password' ? (
          <Input.Password
            type={type}
            name={inputName}
            id={id}
            placeholder={placeHolder}
            className={`mt-2 min-w-custom-input ${
              fieldError ? 'border border-red-700' : ''
            }`}
            required={!isOptional}
            value={value}
            onChange={handleInputChange}
          />
        ) : (
          <Input
            type={type}
            name={inputName}
            id={id}
            placeholder={placeHolder}
            className={`mt-2 min-w-custom-input ${
              fieldError ? 'border border-red-700' : ''
            }`}
            required={!isOptional}
            value={value}
            onChange={handleInputChange}
          />
        )}
      </label>
      {showErrorMsg && (
        <div className="pt-2 text-sm text-red-700">{errMsg}</div>
      )}
    </div>
  );
}

export default CustomFormInput;
