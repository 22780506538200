import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppRoutes } from '../../types';
import { CommonConstants } from '../../constants';

interface AppState {
  currentRoute: AppRoutes;
  currentRouteName: string;
  previousPage: string;
}

const initialState: AppState = {
  currentRoute: AppRoutes.DASHBOARD,
  currentRouteName: '',
  previousPage: '',
};

// Create a Redux slice for the app state
const pageSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setActiveRoute: (state, action: PayloadAction<AppRoutes>) => {
      state.currentRoute = action.payload;
    },
    setPreviousPage: (state, action: PayloadAction<string>) => {
      localStorage.setItem('previousPage', action.payload);
      state.previousPage = action.payload;
    },
    setCurrentRouteName: (state, action: PayloadAction<string>) => {
      localStorage.setItem(CommonConstants.CURRENT_ROUTE_NAME, action.payload);
      state.currentRouteName = action.payload;
    },
  },
});

// Export the action creator
export const { setActiveRoute, setPreviousPage, setCurrentRouteName } =
  pageSlice.actions;

// Export the reducer
export default pageSlice.reducer;
