import { createApi } from '@reduxjs/toolkit/query/react';
import {
  SharedConfigEntity,
  SharedConfigType,
  SharedEndUserProfileEntity,
  SharedUserJourneyType,
  SharedLocationEntity,
  SharedProvinceEntity,
  SharedEndUserTransactionEntity,
  SharedEndUserReferralInfoEntity,
  SharedEndUserCoachingEntity,
  TypeWithCompanyOrBucketDetails,
  SharedEndUserReferralEntity,
  SharedEndUserReferralLedgerEntity,
  SharedEndUserDocumentsEntity,
  SharedBucketDocumentsEntity,
  TypeWithBucketInfo,
} from '@shared/api-responses';
import {
  SharedUserProfileDto,
  SharedUpdateUserJourneyDto,
  SharedEndUserTransactionDto,
  SharedEndUserCoachingDto,
  SharedEndUserReferralDto,
  SharedSendEmailDto,
  SharedDocusignUserSignDto,
  SharedBucketDocumentParams,
} from '@shared/api-inputs';

import customFetchBase from './customFetchBase';
import { setUserMetadata } from '../features/userSlice';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customFetchBase,
  tagTypes: ['users'],
  endpoints: (builder) => ({
    userJourney: builder.query<SharedUserJourneyType, null>({
      query() {
        return {
          url: 'client-users/journey',
          method: 'GET',
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setUserMetadata(data));
        }
      },
    }),
    updateUserJourney: builder.mutation<
      SharedUserJourneyType,
      SharedUpdateUserJourneyDto
    >({
      query(body) {
        return {
          url: 'client-users/journey',
          method: 'PATCH',
          body,
          credentials: 'include',
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(setUserMetadata(data));
        }
      },
    }),
    dropdownValues: builder.query<SharedConfigEntity[], SharedConfigType>({
      query(configName: SharedConfigType) {
        return {
          url: `client-users/configType/${configName}`,
          method: 'GET',
        };
      },
    }),
    locations: builder.query<SharedLocationEntity[], null>({
      query() {
        return {
          url: 'client-users/locations',
          method: 'GET',
        };
      },
    }),
    states: builder.query<SharedProvinceEntity[], number>({
      query(countryId: number) {
        return {
          url: `client-users/provinces/${countryId}`,
          method: 'GET',
        };
      },
    }),
    getStateInfo: builder.query<
      SharedProvinceEntity,
      { countryId: number; configValue: number }
    >({
      query(params: { countryId: number; configValue: number }) {
        return {
          url: `client-users/provinces/${params.countryId}/${params.configValue}`,
          method: 'GET',
        };
      },
    }),
    getSignedUrl: builder.query<
      { signedUrl: string; fileName: string },
      string
    >({
      query(filePrefix: string) {
        return {
          url: `client-users/signedUrl?filePrefix=${filePrefix}`,
          method: 'GET',
        };
      },
    }),
    getUserProfile: builder.query<
      SharedEndUserProfileEntity,
      { email: string }
    >({
      query(data) {
        return {
          url: `client-users/profile?email=${encodeURIComponent(data.email)}`,
          method: 'GET',
        };
      },
    }),
    updateUserProfile: builder.mutation<
      SharedEndUserProfileEntity,
      SharedUserProfileDto
    >({
      query(body) {
        return {
          url: 'client-users/profile',
          method: 'PATCH',
          body,
          credentials: 'include',
        };
      },
    }),
    getUserTransactions: builder.query<
      TypeWithBucketInfo<SharedEndUserTransactionEntity>[],
      { email: string }
    >({
      query(data) {
        return {
          url: `client-users/transactions?email=${encodeURIComponent(
            data.email,
          )}`,
          method: 'GET',
        };
      },
    }),
    upsertTransaction: builder.mutation<
      SharedEndUserTransactionEntity,
      SharedEndUserTransactionDto
    >({
      query(body) {
        return {
          url: 'client-users/transaction',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
    }),
    updateBucketTransaction: builder.mutation<
      SharedEndUserTransactionEntity[],
      SharedEndUserTransactionDto
    >({
      query(body) {
        return {
          url: 'client-users/transaction/multiple-bucket',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
    }),
    createMulitpleTransaction: builder.mutation<
      SharedEndUserTransactionEntity[],
      SharedEndUserTransactionDto[]
    >({
      query(body) {
        return {
          url: 'client-users/transaction/multiple',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
    }),
    createMulitpleBucketTransaction: builder.mutation<
      SharedEndUserTransactionEntity[],
      SharedEndUserTransactionDto[]
    >({
      query(body) {
        return {
          url: 'client-users/transaction/multiple-bucket',
          method: 'POST',
          body,
          credentials: 'include',
        };
      },
    }),
    getUserReferralInfo: builder.query<
      SharedEndUserReferralInfoEntity,
      { email: string }
    >({
      query(data) {
        return {
          url: `client-users/referral-info?email=${encodeURIComponent(
            data.email,
          )}`,
          method: 'GET',
        };
      },
    }),
    getUserCoachings: builder.query<
      TypeWithCompanyOrBucketDetails<SharedEndUserCoachingEntity>[],
      { email: string }
    >({
      query(data) {
        return {
          url: `client-users/mentorships?email=${encodeURIComponent(
            data.email,
          )}`,
          method: 'GET',
        };
      },
    }),
    upsertCoaching: builder.mutation<
      SharedEndUserCoachingEntity,
      SharedEndUserCoachingDto
    >({
      query(body) {
        return {
          url: 'client-users/mentorship',
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
    }),
    getUserReferrals: builder.query<
      SharedEndUserReferralEntity[],
      { email: string }
    >({
      query(data) {
        return {
          url: `referral/user-referrals?email=${encodeURIComponent(
            data.email,
          )}`,
          method: 'GET',
        };
      },
    }),
    getUserReferralLedger: builder.query<
      Array<SharedEndUserReferralLedgerEntity>,
      { email: string }
    >({
      query(data) {
        return {
          url: `referral/referral-ledger?email=${encodeURIComponent(
            data.email,
          )}`,
          method: 'GET',
        };
      },
    }),
    upsertUserReferrals: builder.mutation<
      SharedEndUserReferralEntity,
      SharedEndUserReferralDto
    >({
      query(body) {
        return {
          url: `referral/user-referrals?email=${encodeURIComponent(
            body.referrerEmail,
          )}`,
          method: 'PUT',
          body,
          credentials: 'include',
        };
      },
    }),
    sendCustomEmail: builder.mutation<string, SharedSendEmailDto>({
      query: (data) => ({
        url: 'client-users/send-custom-email',
        method: 'POST',
        body: data,
      }),
    }),
    processUserSign: builder.mutation<string, SharedDocusignUserSignDto>({
      query: (data) => ({
        url: 'client-users/docusign-user-sign',
        method: 'POST',
        body: data,
      }),
    }),
    getUserDocuments: builder.query<
      Array<TypeWithCompanyOrBucketDetails<SharedEndUserDocumentsEntity>>,
      { email: string }
    >({
      query(data) {
        return {
          url: `client-users/user-documents?email=${encodeURIComponent(
            data.email,
          )}`,
          method: 'GET',
        };
      },
    }),
    getBucketDocumentsByDocumentType: builder.query<
      SharedBucketDocumentsEntity[],
      SharedBucketDocumentParams
    >({
      query(params) {
        return {
          url: 'client-users/bucket-documents',
          method: 'GET',
          params,
        };
      },
    }),
  }),
});

export const {
  useUserJourneyQuery,
  useDropdownValuesQuery,
  useUpdateUserJourneyMutation,
  useGetSignedUrlQuery,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useLocationsQuery,
  useStatesQuery,
  useGetStateInfoQuery,
  useGetUserReferralInfoQuery,
  useGetUserTransactionsQuery,
  useUpsertTransactionMutation,
  useCreateMulitpleTransactionMutation,
  useGetUserCoachingsQuery,
  useUpsertCoachingMutation,
  useGetUserReferralsQuery,
  useUpsertUserReferralsMutation,
  useSendCustomEmailMutation,
  useCreateMulitpleBucketTransactionMutation,
  useUpdateBucketTransactionMutation,
  useGetUserReferralLedgerQuery,
  useProcessUserSignMutation,
  useGetUserDocumentsQuery,
  useGetBucketDocumentsByDocumentTypeQuery,
} = userApi;
