import loginHero from '../assets/login-page-content.png';

function LoginSignupHero() {
  return (
    <div className="hidden justify-end xl:flex xl:w-1/2">
      <img src={loginHero} alt="login" />
    </div>
  );
}
export default LoginSignupHero;
