import { useState } from 'react';
import { SharedEndUserRoleType } from '@shared/api-inputs';

import startupStep1 from '../../assets/startup-work-1.png';
import startupStep2 from '../../assets/startup-work-2.png';
import startupStep3 from '../../assets/startup-work-3.png';

import investorStep1 from '../../assets/investor-work-1.png';
import investorStep2 from '../../assets/investor-work-2.png';
import investorStep3 from '../../assets/investor-work-3.png';

import advisorStep1 from '../../assets/advisor-work-1.png';
import advisorStep2 from '../../assets/advisor-work-2.png';
import advisorStep3 from '../../assets/advisor-work-3.png';

const SectionWiseData = {
  COMPANY: {
    sectionName: 'Startups',
    sectionHeader:
      'Grow, Transform and Scale your Startups. Our platform provides the tools, resources, and connections you need to propel your Startups forward.',
    imageUrls: [startupStep1, startupStep2, startupStep3],
    stepNames: [
      'Sign Up For Free',
      'Tell Us About Your Startup',
      'Seek For Investments and Advisors',
    ],
    stepContent: [
      [
        'Click "Sign Up"',
        'Choose the Pitch your Startup option.',
        'Provide Basic Information like your name and email.',
      ],
      [
        "Enter your startup's name, industry, and location.",
        'Indicate your current stage and funding needs.',
        'Add key details about founders and team members.',
      ],
      [
        'Complete your profile and upload your pitch deck.',
        "Specify the type of investment you're seeking.",
        'Once selected, WH team will listen to your Pitch, until then explore potential Advisors, connect, and engage.',
      ],
    ],
  },
  INVESTOR: {
    sectionName: 'Investors',
    sectionHeader:
      'Invest with Confidence and Impact. Our platform offers the insights, opportunities, and connections you need to make meaningful investments.',
    imageUrls: [investorStep1, investorStep2, investorStep3],
    stepNames: [
      'Sign Up For Free',
      'Investment Preferences',
      'Profile Enhancement and Access',
    ],
    stepContent: [
      [
        'Click "Sign Up"',
        'Choose the Start Investing option.',
        'Provide Basic Information like your name and email.',
      ],
      [
        'Specify your Investment Criteria.',
        'Let us know your industries of interest, investment sizes, and investment frequency.',
      ],
      [
        'Complete your profile.',
        'Access the syndicated investment opportunities.',
      ],
    ],
  },
  ADVISOR_OR_COACH: {
    sectionName: 'Advisors',
    sectionHeader:
      'Elevate Your Influence and Mentorship. Our platform provides the resources, startups, and connections you need to empower your advisory role.',
    imageUrls: [advisorStep1, advisorStep2, advisorStep3],
    stepNames: [
      'Sign Up For Free',
      'Connect with Startups',
      'Nurture Relationships',
    ],
    stepContent: [
      [
        'Click "Sign Up"',
        'Choose the become an Advisor option.',
        'Provide Basic Information like your name and email.',
      ],
      [
        'Explore startups seeking advice.',
        'Chat with them to help solve their challenges.',
      ],
      ['Build lasting connections with startups.'],
    ],
  },
};

function StatupHowItWorks({
  sectionName,
}: {
  sectionName: 'COMPANY' | 'INVESTOR' | 'ADVISOR_OR_COACH';
}) {
  const selectedSectionData = SectionWiseData[sectionName];
  return (
    <div className="py-8 flex-1 flex flex-col lg:mx-8">
      <div className="flex-1 text-center py-4 text-header-v1 text-2xl xl:text-4xl">
        {selectedSectionData.sectionName}
      </div>
      <div className="flex-1 text-center text-header-v1 xl:text-2xl py-4">
        {selectedSectionData.sectionHeader}
      </div>
      <div className="flex-1 flex flex-col lg:flex-row">
        <div className="flex-1 flex flex-col items-center">
          <div className="">
            <img src={selectedSectionData.imageUrls[0]} alt="Startup 1" />
          </div>
          <div className="flex-1 flex items-center">
            <div
              className="h-10 w-10 p-2 flex-center mr-4"
              style={{ background: 'rgba(194, 19, 255, 0.08)' }}
            >
              1
            </div>
            <div>{selectedSectionData.stepNames[0]}</div>
          </div>
          <div className="flex-1 py-8">
            {selectedSectionData.stepContent[0].map((stepInfo) => (
              <div className="flex-1 py-2 text-center lg:text-left">
                {stepInfo}
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 flex flex-col  items-center lx:px-8">
          <div className="">
            <img src={selectedSectionData.imageUrls[1]} alt="Startup 2" />
          </div>
          <div className="flex-1 flex items-center">
            <div
              className="h-10 w-10 p-2 flex-center mr-4"
              style={{ background: 'rgba(194, 19, 255, 0.08)' }}
            >
              2
            </div>
            <div>{selectedSectionData.stepNames[1]}</div>
          </div>
          <div className="flex-1 py-8">
            {selectedSectionData.stepContent[1].map((stepInfo) => (
              <div className="flex-1 py-2 text-center lg:text-left">
                {stepInfo}
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 flex flex-col items-center pl-4">
          <div className="">
            <img src={selectedSectionData.imageUrls[2]} alt="Startup 3" />
          </div>
          <div className="flex-1 flex items-center">
            <div
              className="h-10 w-10 p-2 flex-center mr-4"
              style={{ background: 'rgba(194, 19, 255, 0.08)' }}
            >
              3
            </div>
            <div>{selectedSectionData.stepNames[2]}</div>
          </div>
          <div className="flex-1 py-8">
            {selectedSectionData.stepContent[2].map((stepInfo) => (
              <div className="flex-1 py-2 text-center lg:text-left">
                {stepInfo}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default function HowItWorks() {
  // HIW - How it works sections
  const [selectedRoleForHIW, setSelectedRoleForHIW] =
    useState<SharedEndUserRoleType>('COMPANY');

  return (
    <div className="min-h-screen flex flex-col">
      <div
        className="flex-1 flex-center flex-col text-2xl xl:text-4xl xl:mr-12 text-header-v1 pb-4"
        style={{ fontStretch: 'condensed' }}
      >
        <div>How does it work?</div>
      </div>
      <div
        className="mx-2 md:mx-auto flex p-2"
        style={{
          borderRadius: '34.5px',
          background: 'rgba(95, 48, 226, 0.21)',
        }}
      >
        <button
          className={`flex-1 text-xl py-2 md:py-4 px-2 sm:px-8 md:px-16 2xl:px-36 ${
            selectedRoleForHIW === 'COMPANY'
              ? 'landing-page-selected-role text-white font-bold'
              : ''
          }`}
          style={{
            borderRadius: '34.5px',
          }}
          onClick={() => {
            setSelectedRoleForHIW('COMPANY');
          }}
        >
          Startups
        </button>
        <button
          className={`flex-1 text-xl py-2 md:py-4 px-2 sm:px-8 md:px-16 2xl:px-36 ${
            selectedRoleForHIW === 'INVESTOR'
              ? 'landing-page-selected-role text-white font-bold'
              : ''
          }`}
          style={{
            borderRadius: '34.5px',
          }}
          onClick={() => {
            setSelectedRoleForHIW('INVESTOR');
          }}
        >
          Investors
        </button>
        <button
          className={`flex-1 text-xl py-2 md:py-4 px-2 sm:px-8 md:px-16 2xl:px-36 ${
            selectedRoleForHIW === 'ADVISOR_OR_COACH'
              ? 'landing-page-selected-role text-white font-bold'
              : ''
          }`}
          style={{
            borderRadius: '34.5px',
          }}
          onClick={() => {
            setSelectedRoleForHIW('ADVISOR_OR_COACH');
          }}
        >
          Advisors
        </button>
      </div>
      <div className="flex-1">
        <div>
          {selectedRoleForHIW === 'COMPANY' && (
            <StatupHowItWorks sectionName="COMPANY" />
          )}
          {selectedRoleForHIW === 'INVESTOR' && (
            <StatupHowItWorks sectionName="INVESTOR" />
          )}
          {selectedRoleForHIW === 'ADVISOR_OR_COACH' && (
            <StatupHowItWorks sectionName="ADVISOR_OR_COACH" />
          )}
        </div>
      </div>
    </div>
  );
}
