export default function InfoSection() {
  return (
    <div className="min-h-screen px-8 second-hero flex flex-col">
      <div
        className="flex-1 flex-center flex-col text-center lg:text-left text-2xl xl:text-4xl xl:mr-12"
        style={{ fontStretch: 'condensed' }}
      >
        <div>
          Diversify Your Portfolio with
          <span className="bg-gradient-to-r from-secondary-v1 to-primary-v1 text-white px-2 mx-1">
            Carefully Curated
          </span>
        </div>
        <div className="lg:pl-12 py-2">
          <span className="bg-gradient-to-r from-secondary-v1 to-primary-v1 text-white px-2 mx-1">
            Cross-Border
          </span>
          Startup Investments
        </div>
      </div>
      <div
        className="hidden flex-1 min-w-full flex-col md:flex-row items-center text-2xl font-medium"
        style={{ fontStretch: 'condensed' }}
      >
        <div className="flex justify-end flex-1">
          <div className="text-center">
            <div className="md:text-2xl xl:text-4xl pb-2 text-header-v1">
              10+
            </div>
            <div>Startups To Invest</div>
          </div>
        </div>
        <div className="flex justify-center flex-1">
          <div className="text-center">
            <div className="md:text-2xl xl:text-4xl pb-2 text-header-v1">
              50+
            </div>
            <div>Investors</div>
          </div>
        </div>
        <div className="flex justify-start flex-1">
          <div className="text-center">
            <div className="md:text-2xl xl:text-4xl pb-2 text-header-v1">
              50+
            </div>
            <div>Coaches</div>
          </div>
        </div>
      </div>
      <div className="flex-1 flex-center text-lg">
        Join our community of Startups, Investors and Advisors, an active and
        ever growing community to motivate and inspire you!
      </div>
    </div>
  );
}
