import { createApi } from '@reduxjs/toolkit/query/react';

import {
  SharedCompanyEntity,
  SharedConfigTypesResponse,
  SharedCompanyStakeHoldersEntity,
  SharedInvestorAdvisorEntity,
  SharedCompanyAddOnServiceEntity,
  SharedCompanyDocumentsEntity,
  SharedCompanyTermsAndFaqsEntity,
  SharedEndUserTransactionEntity,
  SharedEndUserCoachingEntity,
  SharedCompanyAdminConfigEntity,
  SharedInvestorAdvisorWithProfile,
  SharedCompanyWithProfileAndAdmin,
  TypeWithCompanyOrBucketAndEndUserDetails,
  SharedBucketInfoWithCompany,
} from '@shared/api-responses';
import {
  SharedCompanyAddOnServiceParams,
  SharedCompanyDocumentParams,
  SharedCompanyDocumentsDto,
  SharedCompanyTermsAndFaqsDto,
  SharedCompanyTermsAndFaqsParams,
  SharedConfigTypesRequest,
  SharedGetCompanyStakeHolderParams,
  SharedInvestorAdvisorWithProfileDto,
} from '@shared/api-inputs';
import customFetchBase from './customFetchBase';

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: customFetchBase,
  tagTypes: ['roles'],
  endpoints: (builder) => ({
    getCompanies: builder.query<
      SharedCompanyWithProfileAndAdmin[],
      { onlyCompaniesLookingForMentor?: boolean }
    >({
      query(params: { onlyCompaniesLookingForMentor?: boolean }) {
        return {
          url: params.onlyCompaniesLookingForMentor
            ? 'roles/companies?lookingForMentors=true'
            : 'roles/companies',
          method: 'GET',
        };
      },
    }),
    getCompanyDetails: builder.query<SharedCompanyEntity, { email: string }>({
      query(data) {
        return {
          url: `roles/company?email=${encodeURIComponent(data.email)}`,
          method: 'GET',
        };
      },
    }),
    getCompanyDetailsByIdentifier: builder.query<
      SharedCompanyEntity,
      { identifier: string }
    >({
      query(data) {
        return {
          url: `roles/company/details/${data.identifier}`,
          method: 'GET',
        };
      },
    }),
    getCompanyDetailsByCompanyId: builder.query<
      SharedCompanyEntity,
      { id: string }
    >({
      query(data) {
        return {
          url: `roles/company/id/${data.id}`,
          method: 'GET',
        };
      },
    }),
    updateCompanyDetails: builder.mutation<
      SharedCompanyEntity,
      SharedCompanyEntity
    >({
      query(data) {
        return {
          url: 'roles/company',
          method: 'PATCH',
          body: data,
        };
      },
    }),
    getBulkConfigValues: builder.query<
      SharedConfigTypesResponse,
      SharedConfigTypesRequest
    >({
      query(params) {
        return {
          url: 'client-users/configTypes',
          params,
          method: 'GET',
        };
      },
    }),
    getStakeHolderByType: builder.query<
      SharedCompanyStakeHoldersEntity[],
      SharedGetCompanyStakeHolderParams
    >({
      query(params) {
        return {
          url: 'roles/company/stake-holders',
          method: 'GET',
          params,
        };
      },
    }),
    updateCompanyStakeHolders: builder.mutation<
      SharedCompanyStakeHoldersEntity,
      SharedCompanyStakeHoldersEntity
    >({
      query(data) {
        return {
          url: 'roles/company/stake-holders',
          method: 'PUT',
          body: data,
        };
      },
    }),
    getInvestorDetails: builder.query<
      SharedInvestorAdvisorEntity,
      { email: string }
    >({
      query(data) {
        return {
          url: `roles/investor?email=${encodeURIComponent(data.email)}`,
          method: 'GET',
        };
      },
    }),
    updateInvestorDetails: builder.mutation<
      SharedInvestorAdvisorWithProfileDto,
      SharedInvestorAdvisorEntity
    >({
      query(data) {
        return {
          url: 'roles/investor',
          method: 'PATCH',
          body: data,
        };
      },
    }),
    getAdvisorDetails: builder.query<
      SharedInvestorAdvisorEntity,
      { email: string }
    >({
      query(data) {
        return {
          url: `roles/mentor?email=${encodeURIComponent(data.email)}`,
          method: 'GET',
        };
      },
    }),
    updateAdvisorDetails: builder.mutation<
      SharedInvestorAdvisorWithProfileDto,
      SharedInvestorAdvisorEntity
    >({
      query(data) {
        return {
          url: 'roles/mentor',
          method: 'PATCH',
          body: data,
        };
      },
    }),
    getCompanyAddOnServiceByType: builder.query<
      SharedCompanyAddOnServiceEntity[],
      SharedCompanyAddOnServiceParams
    >({
      query(params) {
        return {
          url: 'roles/company/addon-services',
          method: 'GET',
          params,
        };
      },
    }),
    updateCompanyAddOnService: builder.mutation<
      SharedCompanyAddOnServiceEntity,
      Partial<SharedCompanyAddOnServiceEntity>
    >({
      query(data) {
        return {
          url: 'roles/company/addon-services',
          method: 'PUT',
          body: data,
        };
      },
    }),
    getCompanyDocumentsByDocumentType: builder.query<
      SharedCompanyDocumentsEntity[],
      SharedCompanyDocumentParams
    >({
      query(params) {
        return {
          url: 'roles/company/documents',
          method: 'GET',
          params,
        };
      },
    }),
    updateCompanyDocuments: builder.mutation<
      SharedCompanyDocumentsDto,
      Partial<SharedCompanyDocumentsEntity>
    >({
      query(data) {
        return {
          url: 'roles/company/documents',
          method: 'PUT',
          body: data,
        };
      },
    }),
    getCompanyTermsAndFaqs: builder.query<
      SharedCompanyTermsAndFaqsEntity[],
      SharedCompanyTermsAndFaqsParams
    >({
      query(params) {
        return {
          url: 'roles/company/terms-and-faqs',
          method: 'GET',
          params,
        };
      },
    }),
    updateCompanyTermsAndFaqs: builder.mutation<
      SharedCompanyTermsAndFaqsDto,
      Partial<SharedCompanyTermsAndFaqsEntity>
    >({
      query(data) {
        return {
          url: 'roles/company/terms-and-faqs',
          method: 'PUT',
          body: data,
        };
      },
    }),
    getInvestorsInCompany: builder.query<
      SharedEndUserTransactionEntity[],
      { email: string }
    >({
      query(data) {
        return {
          url: `roles/transactions?email=${encodeURIComponent(data.email)}`,
          method: 'GET',
        };
      },
    }),
    getAdvisorsInCompany: builder.query<
      TypeWithCompanyOrBucketAndEndUserDetails<SharedEndUserCoachingEntity>[],
      { email: string }
    >({
      query(data) {
        return {
          url: `roles/mentorships?email=${encodeURIComponent(data.email)}`,
          method: 'GET',
        };
      },
    }),
    getCompanyAdminConfig: builder.query<
      SharedCompanyAdminConfigEntity,
      { companyId: string }
    >({
      query(data) {
        return {
          url: `roles/company/admin-configs/${data.companyId}`,
          method: 'GET',
        };
      },
    }),
    getAdvisorList: builder.query<SharedInvestorAdvisorWithProfile[], null>({
      query() {
        return {
          url: 'roles/mentors',
          method: 'GET',
        };
      },
    }),
    getBucketDetails: builder.query<SharedBucketInfoWithCompany[], null>({
      query() {
        return {
          url: 'roles/buckets',
          method: 'GET',
        };
      },
    }),
    getBucketDetailsById: builder.query<
      SharedBucketInfoWithCompany,
      { bucketId: string }
    >({
      query(data) {
        return {
          url: `roles/bucket/${data.bucketId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyDetailsQuery,
  useGetCompanyDetailsByIdentifierQuery,
  useGetCompanyDetailsByCompanyIdQuery,
  useUpdateCompanyDetailsMutation,
  useGetBulkConfigValuesQuery,
  useGetStakeHolderByTypeQuery,
  useUpdateCompanyStakeHoldersMutation,
  useGetInvestorDetailsQuery,
  useUpdateInvestorDetailsMutation,
  useGetAdvisorDetailsQuery,
  useUpdateAdvisorDetailsMutation,
  useGetCompanyAddOnServiceByTypeQuery,
  useUpdateCompanyAddOnServiceMutation,
  useGetCompanyDocumentsByDocumentTypeQuery,
  useUpdateCompanyDocumentsMutation,
  useGetCompanyTermsAndFaqsQuery,
  useUpdateCompanyTermsAndFaqsMutation,
  useGetInvestorsInCompanyQuery,
  useGetAdvisorsInCompanyQuery,
  useGetCompanyAdminConfigQuery,
  useGetAdvisorListQuery,
  useGetBucketDetailsQuery,
  useGetBucketDetailsByIdQuery,
} = rolesApi;
