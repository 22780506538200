import { useNavigate } from 'react-router-dom';

import startupImage from '../../assets/startup.png';
import investorsImage from '../../assets/investor.png';
import advisorsImage from '../../assets/advisor.png';
import LandingPageButtons from '../../components/LandingPageButtons';
import { AppRoutes } from '../../types';

export default function RoleSections() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen px-8 third-hero flex flex-col">
      <div
        className="flex-1 flex-center flex-col text-center lg:text-left text-2xl xl:text-4xl xl:mr-12 pb-4"
        style={{ fontStretch: 'condensed' }}
      >
        <div>
          <span className="hidden xl:block">
            We are a platform where
            <span className="pl-2 font-bold">Innovations</span>
            <span className="px-2">and</span>
            <span className="font-bold">Investments</span>
            <span className="bg-gradient-to-r from-secondary-v1 to-primary-v1 text-white px-2 mx-2">
              Flourish
            </span>
          </span>
          <div className="xl:hidden">
            We are a platform where
            <span className="pl-1 font-bold">Innovations</span> and
          </div>
          <div className="xl:hidden">
            <span className="font-bold">Investments</span>{' '}
            <span className="bg-gradient-to-r from-secondary-v1 to-primary-v1 text-white px-2 mx-2">
              Flourish
            </span>
          </div>
        </div>
      </div>
      <div className="flex-1 flex flex-col lg:flex-row py-4">
        <div className="flex-1 flex flex-col lg:items-center">
          <div className="flex justify-center">
            <img src={startupImage} alt="Startups" />
          </div>
          <div className="flex-1 flex-center flex-col">
            <div className="text-header-v1 text-4xl py-4 lg:py-6">Startups</div>
            <div className="py-4 lg:py-6">
              Elevate Your Startups to the Next Level - Access Investments,
              Resources, and Connections to Propel Your Growth.
            </div>
          </div>
          <div className="flex-1 flex justify-center pb-6">
            <LandingPageButtons
              defaultText="Pitch your Startup"
              hoverText="SURE LETS DO IT"
              onClick={() => {
                navigate(`${AppRoutes.SIGNUP}?userRole=COMPANY`);
              }}
              customClass="bg-secondary-v1"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col xl:px-8">
          <div className="flex justify-center">
            <img src={investorsImage} alt="Investors" />
          </div>
          <div className="flex-1 flex-center flex-col">
            <div className="text-header-v1 text-4xl py-4 lg:py-6">
              Investors
            </div>
            <div className="py-4 lg:py-6">
              Democratizing and Simplifying Private Investments in cross-border
              Startups curated by our experienced investment team. Empowering
              and Diversifying Every Portfolio!
            </div>
          </div>
          <div className="flex-1 flex justify-center pb-6">
            <LandingPageButtons
              defaultText="START INVESTING"
              hoverText="SURE LETS DO IT"
              onClick={() => {
                navigate(`${AppRoutes.SIGNUP}?userRole=INVESTOR`);
              }}
              customClass="bg-primary-v1"
            />
          </div>
        </div>
        <div className="flex-1 flex flex-col">
          <div className="flex justify-center">
            <img src={advisorsImage} alt="Advisors" />
          </div>
          <div className="flex-1 flex-center flex-col">
            <div className="text-header-v1 text-4xl py-4 lg:py-6">Advisors</div>
            <div className="py-4 lg:py-6">
              Unlock Your Potential as an Advisor or Coach - Share Your Wisdom
              and Nurture Tomorrow&apos;s Innovators in our Curated Ecosystem of
              Startups.
            </div>
          </div>
          <div className="flex-1 flex justify-center pb-6">
            <LandingPageButtons
              defaultText="BECOME AN ADVISOR"
              hoverText="SURE LETS DO IT"
              onClick={() => {
                navigate(`${AppRoutes.SIGNUP}?userRole=ADVISOR_OR_COACH`);
              }}
              customClass="bg-secondary-v1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
